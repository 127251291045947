// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"

import "chartkick/chart.js"
import { Chart } from "chart.js";
import annotationPlugin from "chartjs-plugin-annotation";

// Registrar el plugin de anotación con Chart.js
Chart.register(annotationPlugin);

// import "trix"
// import "@rails/actiontext"
