import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  toggle(event) {
    // event.stopPropagation(); // COMENTAR ESTO TEMPORALMENTE
    const raidId = this.element.dataset.raidId;
    const box = document.getElementById(`raid_box_${raidId}`);
  
    document.querySelectorAll("[id^='raid_box_']").forEach((el) => {
      if (el.id !== `raid_box_${raidId}`) el.classList.add("hidden");
    });
  
    if (box) {
      const isHidden = box.classList.contains("hidden");
      document.removeEventListener("click", this.hideBox);
      if (isHidden) {
        box.classList.remove("hidden");
        document.addEventListener("click", this.hideBox.bind(this, box));
      } else {
        box.classList.add("hidden");
      }
    }
  }

  hideBox(box, event) {
    if (!box.contains(event.target) && !this.element.contains(event.target)) {
      box.classList.add("hidden");
      document.removeEventListener("click", this.hideBox);
    }
  }
}
