import { Controller } from "@hotwired/stimulus"
import { put, post } from "@rails/request.js"

export default class extends Controller {
  static targets = ["member", "placeholder"];

  dragStart(event) {
    console.log("start")
    event.target.style.opacity = "0.4";
    this.dragSrcEl = event.target;

    event.dataTransfer.effectAllowed = "move";
    event.dataTransfer.setData("text/html", event.target.innerHTML);
  }

  dragEnter(event) {
    console.log("enter")

    document.querySelectorAll(".over").forEach((el) => {
      el.classList.remove("over");
    });

    event.target.classList.add("over");

    if (event.preventDefault) {
      event.preventDefault();
    }
    return false;
  }

  dragOver(event) {
    if (event.preventDefault) {
      event.preventDefault();
    }

    return false;
  }

  dragLeave(event) {
    event.target.classList.remove("over");
    this.resetOpacity();
  }

  drop(event) {
    document.querySelectorAll(".over").forEach((el) => {
      el.classList.remove("over");
    });

    const container = event.target.closest("#party-slot");
    const party_id = container.dataset.partyId;
    const user_id = this.dragSrcEl.dataset.userId
    const placeholder = this.dragSrcEl.dataset.placeholder
    const classRole = this.dragSrcEl.dataset.classRole;
    const filterParams = container.dataset.filterParams ? JSON.parse(container.dataset.filterParams) : {};

    event.stopPropagation();
    event.target.classList.remove("over");
    this.resetOpacity();

    this.dragSrcEl = null;

    console.log(filterParams)

    put(`/parties/${party_id}`, {
      body: JSON.stringify({ user_id: user_id, filterParams: filterParams, placeholder: placeholder, class_role: classRole }),
      contentType: "application/json",
      responseKind: "turbo-stream"
    })
  }

  allowDrop(event) {
    event.preventDefault();
  }

  removeMember(event) {
    event.preventDefault();
    console.log("Dropped in Trash!");
    const raidId = event.currentTarget.dataset.raidId;
    const userId = event.currentTarget.dataset.userId;
    const placeholderId = event.currentTarget.dataset.placeholderId;
    const filterParams = event.currentTarget.dataset.filterParams ? JSON.parse(event.currentTarget.dataset.filterParams) : {};

    post(`/raids/${raidId}/remove_member`, {
      body: JSON.stringify({ user_id: userId, placeholder_id: placeholderId, filterParams: filterParams }),
      contentType: "application/json",
      responseKind: "turbo-stream"
    })
  }

  dragEnd() {
    this.resetOpacity();
  }

  resetOpacity() {
    this.memberTargets.forEach((el) => {
      el.style.opacity = "1";
    });
  }

  connect(){
  }
}
