import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["modal"];
  
  connect() {
    this.amount = null;
  }

  openModal(event) {
    event.preventDefault();
    
    this.amount = event.target.dataset.amount;
    document.getElementById("bid-confirmation-modal").classList.remove("hidden");
  }

  closeModal() {
    document.getElementById("bid-confirmation-modal").classList.add("hidden");
  }
  confirmBid(event) {
    const bidReason = event.target.dataset.reason;
    const amount = this.amount;
    const auctionId = this.element.dataset.auctionId;

    fetch("/bids", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "X-CSRF-Token": document.querySelector("meta[name='csrf-token']").content
        },
        body: JSON.stringify({
            amount: amount,
            auction_id: auctionId,
            reason: bidReason
        })
    })
    .then(response => {
        if (response.ok) {
            Turbo.visit(window.location.href, { action: "replace" }); // Recargar la página con Turbo
        } else {
            console.error("Error placing bid");
        }
    });

    this.closeModal();
}
}
