import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["menu"];

  connect() {
    this.menuTarget.classList.add("hidden");
  }


  toggleDropdown(event) {
    event.stopPropagation();
    
    document.querySelectorAll(".dropdown-menu").forEach((el) => {
      if (el !== this.menuTarget) {
        el.classList.add("hidden");
      }
    });

    const roleKey = event.target.getAttribute("data-key");
    const menu = document.querySelector(`[data-menu-key='${roleKey}']`);

    if (menu) {
        menu.classList.toggle("hidden");
    }
    document.addEventListener("click", (e) => {
        if (!this.element.contains(e.target) && !menu.contains(e.target)) {
            menu.classList.add("hidden");
        }
    }, { once: true });
  }

  selectClass(event) {
    event.stopPropagation();
  
    const selectedClass = event.target.innerText.trim().split(" -")[0];
    const roleKey = event.target.closest("div").getAttribute("data-menu-key");
  
    const classNameSpan = document.querySelector(`[data-class-role='${roleKey}']`);
    classNameSpan.classList.remove("hidden");
    classNameSpan.innerText = selectedClass;

    classNameSpan.closest("#draggable-placeholder").dataset.classRole = selectedClass;

    const menu = event.target.closest(".dropdown-menu");
    if (menu) {
      menu.classList.add("hidden");
    }
  }
}
